import React, { type ReactElement, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useIdleTimer } from 'react-idle-timer';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { UserContextProvider } from '../context/UserContext';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import '../styles/globals.css';
import { type NextPage } from 'next';

const publishableKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string;

export default function App({ Component, pageProps }: AppProps & { Component: NextPage }): ReactElement {
  const router = useRouter();

  const stripePromise = useMemo(() => loadStripe(publishableKey), []);

  const signOutAuth0 = async () => {
    try {
      await router.push('/api/auth/logout');
    } catch (error) {
      console.error(error);
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 minutes of inactivity
    onIdle: signOutAuth0,
  });

  return (
    <UserProvider>
      <UserContextProvider>
        <Elements stripe={stripePromise}>
          <Head>
            <script
              id="ze-snippet"
              src="https://static.zdassets.com/ekr/snippet.js?key=b9517df0-004b-4a55-8207-a8f4ac649e95"
            />
          </Head>
          <Component {...pageProps} />
        </Elements>
      </UserContextProvider>
    </UserProvider>
  );
}
