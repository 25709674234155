import React, { createContext, useContext, useEffect, useState, type ReactNode, useMemo } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';

/**
 * User type definition.
 */
type User = {
    name: string;
    email: string;
    nickname: string;
    picture: string;
    sub: string;
    acr?: string;
    amr?: string[];
    email_verified?: boolean;
    sid?: string;
    updated_at?: string;
    role?: string;
    logins_count?: number;
};

type UserContextType = {
    user: User | null;
    isLoading: boolean;
};

/**
 * Create the UserContext with a default value of null.
 */
const UserContext = createContext<UserContextType>({ user: null, isLoading: true });

/**
 * Custom hook to access the UserContext.
 *
 * @returns {User | null} The current user context.
 */
export const useUserContext = () => {
    return useContext(UserContext);
};

/**
 * UserContextProvider component.
 *
 * @param {ReactNode} children - The children components.
 * @returns {JSX.Element} The UserContext provider.
 */
export const UserContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { user, isLoading } = useUser(); // Get the user object from useUser

    /**
     * Initialize the state for the user data.
     */
    const [userData, setUserData] = useState<User>({
        name: '',
        email: '',
        nickname: '',
        picture: '',
        sub: '',
        acr: '',
        amr: [],
        email_verified: false,
        sid: '',
        updated_at: '',
    });

    /**
     * Effect hook to update the user data when the Auth0 user or loading state changes.
     *
     * @effect
     */
    useEffect(() => {
        if (!isLoading && user) {
            const userData: User = {
                name: user.name || '',
                email: user.email || '',
                nickname: user.nickname || '', // Add nickname property
                picture: user.picture || '', // Add picture property
                sub: user.sub || '', // Add sub property
                acr: user.acr as string || '',
                amr: user.amr as string[] || [],
                email_verified: user.email_verified || false,
                sid: user.sid as string || '',
                updated_at: user.updated_at || '',
            };

            setUserData(userData);
        }
    }, [isLoading, user]);

    const value = useMemo(() => ({ user: userData, isLoading }), [userData, isLoading]);

    /**
     * Render the UserContext.Provider component.
     *
     * @returns {JSX.Element} The UserContext provider wrapping the children.
     */
    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};
