import { BrowserTracing, Replay, init } from '@sentry/nextjs';

// NOTE: This could be dynamic depending on the APP_ENV the UI is at.
export const dsn = 'https://201132c6de1af268917228d9ff68a183@o4505683930120192.ingest.sentry.io/4505710869872640';

// Performance Monitoring
const tracesSampleRate = 1.0; // Capture 100% of the transactions, reduce in production!

// Session Replay
const replaysSessionSampleRate = 0.1; // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
const replaysOnErrorSampleRate = 1.0; // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

const browserTracing = new BrowserTracing();
const replay = new Replay();

const integrations = [browserTracing, replay];

export const SENTRY_CONFIG_CLIENT = {
    dsn,
    integrations,
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
};

export const SENTRY_CONFIG_SERVER = {
    dsn,
    tracesSampleRate,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
};


export const initClient = () => {
    init(SENTRY_CONFIG_CLIENT);
};

export const initServer = () => {
    init(SENTRY_CONFIG_SERVER);
};

export const initEdge = () => {
    // At the moment Edge config uses same config as server
    init(SENTRY_CONFIG_SERVER);
};
